const trackEvent = (firebase, uid, event, metadata) => {
  try {
    const callable = firebase.functions.httpsCallable('backend-tracker');

    const data = {
      userTimestamp: Date.now(),
      uid,
      event,
      metadata,
    };
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV !== 'development') {
      callable(data);
    }

  } catch (error) {
    console.log('error', error);
  }
};

export default trackEvent;

export function trackExitEvent(firebase, uid, eventType, eventData) {
  trackEvent(firebase, uid, eventType, eventData);
}

/**
 * @param firebase
 * @param classGroupInfo Object { contentId | channelId , courseId,
 * classGroupId , channelId}
 * @param uid
 * @param ref
 * @param eventType
 */
export function trackFileManagementEvent(firebase, classGroupInfo, uid, ref, eventType) {
  const metadata = {
    type: EVENT_CONSTANTS.UPLOAD_ASSIGNMENT,
    ...classGroupInfo,
    fileRef: ref.fullPath,
  };
  trackEvent(firebase, uid, eventType, metadata);
}

export const EVENT_CONSTANTS = {
  CONTENT_VIEW: 'content_view',
  COURSE_VIEW: 'course_view',
  ACTIVITY_VIEW: 'activity_view',
  ACTIVITY_COMPLETE: 'activity_complete',

  CONTENT_LOAD: 'content_load',
  COURSE_LOAD: 'course_load',
  ACTIVITY_LOAD: 'activity_load',

  CONTENT_EXIT: 'content_exit',
  COURSE_EXIT: 'course_exit',
  ACTIVITY_EXIT: 'activity_exit',

  QUIZ: 'Quiz',
  TEST: 'Test',
  TEXT: 'Text',
  UPLOAD_ASSIGNMENT: 'UploadAssignment',
  UPLOADED_TEXT: 'UploadedText',
  VIDEO: 'Video',
  WEBINAR: 'Webinar',

  FILE_DELETE: 'file_delete',
  FILE_UPLOAD: 'file_upload',


  END_COURSE_VIEW: 'end_course_view',
  DEVICE_CHECK_VIEW: 'device_check_view',
  BROWSER_CHECK_VIEW: 'browser_check_view',
};
