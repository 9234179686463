import getTransformedContentfulEntryById
  from '../pages/admin/contentPreview/services/getTransformedEntryById';
import getContentfulEntryById
  from '../pages/admin/contentPreview/services/getEntryById';

export const DEVICE_CHECK_TEXT_CONTENTFUL_ENTRY = '4AAPQ1z0gDV7vlojfBZh33';

const noop = () => {
};

export const fetchEntryFromContentful = async (firebase, entryId, setStateFunction, onStart = noop, onComplete = noop) => {
  onStart();
  const result = await getTransformedContentfulEntryById(firebase, entryId);
  setStateFunction(state => ({
    ...state,
    data: result.data.entry,
    loading: false,
    firebaseStarted: true,
  }));
  onComplete();
};

export async function getTextFromContentfulEntry(firebase, entryId) {
  const result = await getContentfulEntryById(firebase, entryId);
  const output = {};
  Object.entries(result.data.entry.fields).forEach(([key, value]) => output[key] = value['en-US']);
  return { result, output };
}
