export const CONTENT_TYPES = {
  COURSE_CONTENT_QUIZ_MULTIPLE_CHOICE_QUESTION: 'courseContentQuizMultipleChoiceQuestion',
  COURSE_CONTENT_QUIZ: 'courseContentQuiz',
  COURSE_CONTENT_TEST: 'courseContentTest',
  COURSE_CONTENT_UPLOADED_TEXT: 'courseContentUploadedText',
  COURSE_CONTENT_UPLOAD_ASSIGNMENT: 'courseContentUploadAssignment',
  COURSE_CONTENT_WEBINAR: 'courseContentWebinar',
  COURSE_CONTENT_QUIZ_OPEN_QUESTION: 'courseContentQuizOpenQuestion',
  COURSE_CONTENT_TEXT: 'courseContentText',
  COURSE_CONTENT_VIDEO: 'courseContentVideo',
  TOPIC: 'topic',
  COURSE: 'course',
  ACTIVITY: 'activity',

};
export const STORAGE_CONSTANTS = {
  TUTOR_UPLOADS: 'tutorUploads',
  STUDENT_UPLOADS: 'studentUploads',
};