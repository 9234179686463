import { useState, useEffect } from 'react';

const useAuthentication = firebase => {
  const [state, setState] = useState({ authUser: null });

  useEffect(() => {

    if (firebase) {
      setState(state => ({
        ...state,
        authUser: JSON.parse(localStorage.getItem('authUser')),
      }));
      setState(state => ({
          ...state,
        })
      );
      const listener = firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem(
            'authUser',
            JSON.stringify(authUser),
          );
          setState(state => ({ ...state, authUser }));
        },
        () => {
          localStorage.removeItem('authUser');
          setState((state) => ({ ...state, authUser: null }));
        },
      );
      return listener;
    }
    return null;
  }, [firebase])

  return state;
}

export default useAuthentication

