const isBrowser = (typeof window !== `undefined`);

const getTransformedContentfulEntryById = async (firebase, entryId) => {
  if (isBrowser) {
    const data = {
      'entryId': entryId,
    };

    // eslint-disable-next-line no-undef
    if (process.env.GATSBY_LOCAL === "true") {
      firebase.functions.useFunctionsEmulator("http://localhost:5001")
    }
    
    try {
      const callable = firebase.functions.httpsCallable('backend-GetTransformedContentfulEntryById');
      return await callable(data);
    } catch (error) {
      console.log(error);
    }
  }
};

export default getTransformedContentfulEntryById;
