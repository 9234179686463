import { serverTime } from '../../../helpers/dateChecker';

export function currentWebinarsTutorIsNotOnline(webinars) {
  const today = serverTime();
  const pastHour = today.getTime() - 60 * 60 * 1000;
  const currentWebinars = webinars.filter(data => data.timestamp > pastHour);
  return (
    currentWebinars.length &&
    currentWebinars.filter(webinar => {
      if (
        !!webinar?.liveParticipants?.length &&
        !webinar?.liveParticipants?.filter(p => p.includes('tutor')).length
      )
        return webinar;
      return null;
    })
  );
}

export function webinarsFromToday(webinars) {
  const today = serverTime();
  const pastTwoHours = today.getTime() - 60 * 60 * 1000 * 2;
  const webinarsInProgress = webinars.filter(
    data => data.startDate > pastTwoHours && data.startDate < today,
  );
  return webinarsInProgress;
}

export function webinarsFromYesterday(webinars) {
  const today = new Date();
  const past24Hours = new Date(Date.now() - 86400 * 1000);
  const webinarsFromYesterday = webinars.filter(
    data => data.startDate > past24Hours && data.startDate < today,
  );
  return webinarsFromYesterday;
}

export function webinarsFromTomorrow(webinars) {
  const today = new Date();
  const next24Hours = new Date(Date.now() + 86400 * 1000);
  const webinarsFromTomorrow = webinars.filter(
    data => data.startDate > today && data.startDate < next24Hours,
  );
  return webinarsFromTomorrow;
}

export function recordingsFromToday(webinars) {
  const today = serverTime();
  const pastTwoHours = today.getTime() - 60 * 60 * 1000 * 2;
  const webinarsInProgress = webinars.filter(
    data => data.timestamp > pastTwoHours && data.timestamp < today,
  );
  return webinarsInProgress;
}

export function recordingsFromYesterday(webinars) {
  const today = new Date();
  const past24Hours = new Date(Date.now() - 86400 * 1000);
  const webinarsFromYesterday = webinars.filter(
    data => data.timestamp > past24Hours && data.timestamp < today,
  );
  return webinarsFromYesterday;
}
