import { useEffect, useState } from 'react'
import { fetchCollectionFromFirestoreOnce} from '../lib/firebaseHelper'

const buildProgrammeData = (classGroups) => {
  return (programme, index) => {

    const modules = programme.modules.map((module) => {
      
      const nextCourses = module.courses.map((courseId) => {
        return {
          id: courseId,
          classGroup: classGroups.find((entry) => {
            return entry.courseIds[0] === courseId
          })
        }
      })
      return {
        ...module,
        courses: nextCourses,
      }
    })
    const next = {
      ...programme,
      modules,
    }
    return next
  }
}

function classGroupsMapper(programmes, modulesWithinProgramme, coursesWithinModule, isTutor) {
  const groupByCourseId = modulesWithinProgramme.reduce((accum, module) => {    
    if (module.order === 0 || isTutor) {
      const startCourse = coursesWithinModule.find((entry) => (entry.moduleId === module.moduleId && (entry.order === 0 || isTutor)))
      
      return (startCourse) ? accum.concat(startCourse.uid) : accum
    }
    return accum
  }, [])
  return (classGroup, index, classGroups) => {
    const classGroupCourseId = classGroup.courseIds[0]
    const next = {
      ...classGroup
    }
    if (groupByCourseId.includes(classGroupCourseId)) {
      next.group = true
      const foundInModule = coursesWithinModule.find((entry) => entry.uid === classGroupCourseId)
      if (foundInModule) {
        const foundInProgramme = modulesWithinProgramme.find((entry) => entry.programmeId === foundInModule.programmeId)
        next.module = foundInProgramme
        next.moduleId = foundInProgramme.moduleId
        if (foundInProgramme) {
          const programme = programmes.find((entry) => entry.uid === foundInProgramme.programmeId)
          next.programmeId = foundInProgramme.programmeId
          next.programme = programme
        }
      }
    } else {
      const foundInModule = coursesWithinModule.find((entry) => entry.uid === classGroupCourseId)
      if (foundInModule) {
        const foundInProgramme = modulesWithinProgramme.find((entry) => entry.programmeId === foundInModule.programmeId)
        next.module = foundInProgramme
        next.moduleId = foundInProgramme.moduleId
        if (foundInProgramme) {
          // const programme = programmes.find((entry) => entry.uid === foundInProgramme.programmeId)
          next.programmeId = foundInProgramme.programmeId
        }
        if (foundInProgramme.order === 0) {
          next.initialModule = true
          next.moduleIndex = foundInProgramme.order
        }
        if (foundInModule.order === 0) {
          next.initialCourse = true
          next.courseIndex = foundInModule.order
          next.module = foundInModule
        }  
      }  
    }

    return next
  }
}

function useCourses(firebase, auth) {
  const [programmes, setProgrammes] = useState()
  const [modulesWithinProgramme, setModulesWithinProgramme] = useState()
  const [coursesWithinModule, setCoursesWithinModule] = useState()
  const [classGroups, setClassGroups] = useState()
  const [combinedClassGroups, setCombinedClassGroups] = useState()
  
  useEffect(() => {
    const combineClassGroups = (programmes, classGroups, modulesWithinProgramme, coursesWithinModule) => {
      const isTutor = firebase.isTutor(auth.authUser)
      // console.log('isTutor', isTutor)
      const combined = classGroups.map(classGroupsMapper(programmes, modulesWithinProgramme, coursesWithinModule, isTutor))
      setCombinedClassGroups(combined)
    }

    const getProgrammes = async () => {
      const data = await fetchCollectionFromFirestoreOnce(firebase.programmes())
      const updatedProgrammes =  data.map(buildProgrammeData(classGroups))
      setProgrammes(updatedProgrammes)
    }
    const getCoursesWithinModule = async () => {
      const data = await fetchCollectionFromFirestoreOnce(firebase.coursesWithinModule())
      setCoursesWithinModule(data)

    }
    const getModulesWithinProgramme = async () => {
      const data = await fetchCollectionFromFirestoreOnce(firebase.modulesWithinProgramme())
      setModulesWithinProgramme(data)

    }
    const getClassGroups = async () => {
      const data = await fetchCollectionFromFirestoreOnce(firebase.userclassGroups(auth.authUser.uid));
      setClassGroups(data)
    }
    if (firebase && auth.authUser && !modulesWithinProgramme) {
      getModulesWithinProgramme()
    }
    if (firebase && auth.authUser && !coursesWithinModule) {
      getCoursesWithinModule()
    }
    if (firebase && auth.authUser && !classGroups) {
      getClassGroups()
    }
    if (firebase && auth.authUser && classGroups && !programmes) {
      getProgrammes()
    }
    if (firebase && auth.authUser && modulesWithinProgramme && coursesWithinModule && classGroups && programmes && !combinedClassGroups) {
      combineClassGroups(programmes, classGroups, modulesWithinProgramme ,coursesWithinModule)
    }

  }, [firebase, auth, modulesWithinProgramme, coursesWithinModule, classGroups, combinedClassGroups, programmes])
  return { programmes, modulesWithinProgramme, coursesWithinModule, classGroups, combinedClassGroups }
}

export default useCourses;
