/* eslint-disable no-undef */
import React from 'react';

const build = process.env.GATSBY_BUILD_ID || 'development';
const commit = process.env.GATSBY_COMMIT_SHA || 'development';

const content = {
  __html: `<!-- commit: ${commit} build: ${build} -->`
}

const Version = () => <div dangerouslySetInnerHTML={content} />

export default Version;