import React, { Fragment, useLayoutEffect, useState } from 'react';
import Version from '../components/Version';
import getFirebase, { FirebaseContext } from '../app/components/Firebase';

const useFirebase = () => {
  const [ firebase, setFirebase] = useState(null);
  useLayoutEffect(() => {
    const loadFirebase = async () => {
      const app = import('firebase/app');
      const auth = import('firebase/auth');
      const firestore = import('firebase/firestore');
      const functions = import('firebase/functions');
      const storage = import('firebase/storage');

      Promise.all([app, auth, firestore, functions, storage, ]).then(values => {
        const firebase = getFirebase(values[0]);  
        setFirebase(firebase);
      });
    };
    if (firebase === null) {
      loadFirebase();
    }
  }, [firebase]);
  return firebase;
}

const Layout = (props) => {
  const firebase = useFirebase();

  return (
    <FirebaseContext.Provider value={firebase}>
      <AppWithFirebase {...props} />
    </FirebaseContext.Provider>
  );
}

const AppWithFirebase = ({ children }) => (
  <Fragment>
    <Version />
    {children}
    <Version />
  </Fragment>
);

export default Layout;
