/* eslint-disable */
import React, { useState } from 'react';

const browser = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(
    /(chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) {
      return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return { name: M[0], version: M[1] };
};

const UNSUPPORTED_BROWSERS = {
  Chrome: 70,
  Firefox: 60,
  IE: 10,
  Edge: 15,
  Safari: 12,
}

const DOWNLOAD_LINKS = {
  Chrome: 'https://www.google.com/chrome/',
  Firefox: 'https://www.mozilla.org/en-US/firefox/',
  IE: 'https://www.microsoft.com/en-us/download/details.aspx?id=41628',
  Edge: 'https://www.microsoft.com/en-us/edge/',
  Safari: 'https://www.apple.com/safari/',
}

const useBrowserCheck = (unsupportedBrowsers = UNSUPPORTED_BROWSERS) => {

  const [detectedBrowser, setDetectedBrowser] = useState(browser());

  const isIE = () => {
    return detectedBrowser.name === 'IE';
  };
  const isEdge = () => {
    return detectedBrowser.name === 'Edge';
  };
  const isMicrosoft = () => {
    return isIE() || isEdge();
  };
  const isFirefox = () => {
    return detectedBrowser.name === 'Firefox';
  };
  const isChrome = () => {
    return detectedBrowser.name === 'Chrome';
  }

  const isSafari = () => {
    return detectedBrowser.name === 'Safari';
  }

  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
  }

  const isBlackBerry = () => {
    return /BlackBerry/i.test(navigator.userAgent);
  }

  const isWindowsMobile = () => {
    return /IEMobile/i.test(navigator.userAgent);
  }

  const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  const isMobile = () => {
    return (
        isAndroid() || isBlackBerry() || isWindowsMobile() || isIOS()
    );
  }

  const isSupported = () => {
    if (unsupportedBrowsers.hasOwnProperty(detectedBrowser.name) && !isMobile()) {
      if (+detectedBrowser.version > unsupportedBrowsers[detectedBrowser.name]) {
        return true;
      }
    }

    return false;
  };
  const checks = {
    isIE: isIE(),
    isEdge: isEdge(),
    isMicrosoft: isMicrosoft(),
    isFirefox: isFirefox(),
    isChrome: isChrome(),
    isSafari: isSafari(),
    isAndroid: isAndroid(),
    isBlackBerry: isBlackBerry(),
    isWindowsMobile: isWindowsMobile(),
    isIOS: isIOS(),
    isMobile: isMobile(),
    isSupported: isSupported(),
  };

  return {
    detectedBrowser,
    checks,
    isIE,
    isEdge,
    isMicrosoft,
    isFirefox,
    isChrome,
    isSafari,
    isAndroid,
    isBlackBerry,
    isWindowsMobile,
    isIOS,
    isMobile,
    isSupported,
    UNSUPPORTED_BROWSERS,
    DOWNLOAD_LINKS,
    platform: navigator.platform,
  }
}

export default useBrowserCheck;


/* eslint-enable */