import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import ChatIcon from '@material-ui/icons/Chat';

import { APP_MESSAGES } from '../../../constants/routes';
import { fetchCollectionFromFirestoreBasic } from '../../lib/firebaseHelper';
import GatsbyLinkedIcon from '../../../components/IconButton';

export const Notifications = (props) => {
  const { authUser, firebase } = props;
  const [unread, setUnread] = useState(false);

  useLayoutEffect(() => {
    let unsubscribe;

    const onListenForChannels = () => {
      try {
        unsubscribe = fetchCollectionFromFirestoreBasic(firebase.userNotificationsUnread(authUser.uid).limit(1), setUnread);
      } catch (error) {
        console.log('error', error);
      }
    };
    if (firebase && authUser && authUser.uid) {
      onListenForChannels();
    }
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase, authUser]);

  return <Box mr={1}>
    {authUser &&
    <Badge color="secondary"
           variant="dot"
           overlap="circular"
           invisible={!unread.length}>
      <GatsbyLinkedIcon to={APP_MESSAGES}>
        <ChatIcon/>
      </GatsbyLinkedIcon>
    </Badge>

    }
  </Box>;
};
