const isBrowser = (typeof window !== `undefined`);

// eventually classGroupId will come from salesforce. 
// if left blank, we use a random id generated by firestore
export const callAddStudentToCohort = async (firebase, userId, orderId, courseId, classGroupId) => {
  const data = {
    userId,
    orderId,
    classGroupId,
    courseId
  };
  return callCloudFunction(firebase, data, 'delivery-callAddStudentToCohort');
};


export const callCreateClassGroup = async (firebase, tutor, tutorAssistant, course, classGroupId, startingDate, mainCollection = 'published') => {
  const data = {
    courseId: course.uid,
    classGroupId,
    tutorId: tutor.uid,
    tutorName: tutor.fullName,
    tutorAssistantId: tutorAssistant.uid,
    tutorAssistantName: tutorAssistant.fullName,
    courseTitle: course.title,
    mainCollection,
    startingDate,
  };
  return callCloudFunction(firebase, data, 'delivery-CreateClassGroup');
};

export async function callCloudFunction(firebase, data, functionName) {
  if (isBrowser) {
    // eslint-disable-next-line no-undef
    if (process.env.GATSBY_LOCAL === "true") {
      firebase.functions.useFunctionsEmulator("http://localhost:5001")
      // console.log('CALL LOCAL', functionName)
    }
    
    const cloudFunction = firebase.functions.httpsCallable(functionName);
    return await cloudFunction(data);
  }
}
