import React, { Fragment, useState, useLayoutEffect } from 'react';
import Container from '@material-ui/core/Container';

import getFirebase, { FirebaseContext } from './Firebase';
import CoursesContext from '../context/CoursesContext'
import useCourses from '../hooks/useCourses'
import useAuthentication from '../hooks/useAuthentication';
import AuthenticatedTopBar from './AuthenticatedTopBar';
import Version from '../../components/Version';
import { AuthUserContext } from './Session';

const isBrowser = () => (typeof window !== 'undefined');
export const useFirebase = () => {
  const [ firebase, setFirebase] = useState(null);
  useLayoutEffect(() => {
    if (isBrowser()) {

      const loadFirebase = async () => {
        const app = import('firebase/app');
        const auth = import('firebase/auth');
        const firestore = import('firebase/firestore');
        const functions = import('firebase/functions');
        const storage = import('firebase/storage');

        Promise.all([app, auth, firestore, functions, storage, ]).then(values => {
          const firebase = getFirebase(values[0]);  
          setFirebase(firebase);
        });
      };
      if (firebase === null) {
        loadFirebase();
      }
    }
  }, [firebase]);
  return firebase;
}

const Layout = (props) => {
  const firebase = useFirebase();
  const auth = useAuthentication(firebase)
  const courses = useCourses(firebase, auth);
  return (
    <FirebaseContext.Provider value={firebase}>
      <AuthUserContext.Provider value={auth && auth.authUser}>
        <CoursesContext.Provider value={courses}>
          <AppWithAuthentication {...props} firebase={firebase} authUser={auth.authUser} />
        </CoursesContext.Provider>
      </AuthUserContext.Provider>
    </FirebaseContext.Provider>
  );
}

const AppWithAuthentication = (props) => {
  return (
    <Fragment>
      <AuthenticatedTopBar {...props} />
      <Container>
      {props.children}
      </Container>
      <Version />
    </Fragment>
  )
};

export default Layout;
