import { useState, useEffect } from 'react';

import tracker from '../app/lib/tracker';

export const EVENTS = {
  PAGE_LOADED: 'page_loaded',
  DATA_REQUEST: 'data_request',
  DATA_LOADED: 'data_loaded',
};

const useTracker = (
  firebase,
  authUserUid,
  eventKey,
  eventData,
  events = [
    EVENTS.PAGE_LOADED,
    EVENTS.DATA_REQUEST,
    EVENTS.DATA_LOADED
  ]) => {
  const [pageLoadedTracked, setPageLoadedTracked] = useState(false);
  const [dataRequestTracked, setDataRequestTrack] = useState(false);
  const [dataLoadTracked, setDataLoadTrack] = useState(false);
  let track = () => {}
  useEffect(() => {
    if (firebase && !pageLoadedTracked && events.includes(EVENTS.PAGE_LOADED)) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
      track = tracker(firebase, authUserUid);
      track(eventKey, { event: EVENTS.PAGE_LOADED, ...eventData });
      setPageLoadedTracked(true);
    }
  },[pageLoadedTracked, firebase]);
  const dataRequestStart = () => {
    if (firebase && !dataRequestTracked && events.includes(EVENTS.DATA_REQUEST)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      track = tracker(firebase, authUserUid);
      track(eventKey, { event: EVENTS.DATA_REQUEST, ...eventData });
      setDataRequestTrack(true);
    }
  }
  const dataRequestCompleted = () => {
    if (firebase && !dataLoadTracked && events.includes(EVENTS.DATA_LOADED)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      track = tracker(firebase, authUserUid);
      track(eventKey, { event: EVENTS.DATA_LOADED, ...eventData });
      setDataLoadTrack(true);
    }
  }
  return {
    track,
    onDataRequest: dataRequestStart,
    onDataResponse: dataRequestCompleted,
  };
}

export default useTracker;
