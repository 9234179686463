export async function getVideoDevices(navigator) {
  const devices = (await navigator.mediaDevices.enumerateDevices());
  const filteredDevices = devices.filter(
    device => device.kind === 'videoinput',
  );
  const deviceIds = filteredDevices.map(device => (
    { id: device.deviceId, label: device.label }
  ));
  return { devices: filteredDevices, deviceIds };
}


export const userMustCheckDevice = async (authUser, navigator, detectedBrowser, currentPlatform) => {
  if (!!authUser.deviceCheck) {
    const { browserData, platform } = authUser.deviceCheck;
    const differentBrowser = !!detectedBrowser && detectedBrowser.name !== browserData.name;
    const olderVersion = !!detectedBrowser && detectedBrowser.version < browserData.version;
    const osChanged = currentPlatform !== platform;

    /*
    // commented until we get a stable definition for browser
    // check process.
    // using cameraChanged() gave bad results when entering in
    //  incognito mode, since we had no permissions, therefore no
    //   access to the list of devices.
    async function cameraChanged() {
      function arrayDiff(a, b) {
        if (a.length !== b.length)
          return true;
        return a.some((item, index) => objectDiff(item, b[index]));
      }

      const { deviceIds } = await getVideoDevices(navigator);
      return (!authUser.deviceCheck.cameraDevices || arrayDiff(authUser.deviceCheck.cameraDevices, deviceIds));
    }
    */
    return differentBrowser || olderVersion || osChanged;
  }

  function objectDiff(a, b) {
    if (Object.keys(a).length !== Object.keys(b).length)
      return true;
    const diff = Object.entries(a).filter(([k, v]) => b[k] !== v);
    return !!diff.length;
  }


};