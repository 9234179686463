import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../../constants/routes';
import { useLocation } from '@reach/router';
import { skipDeviceCheck } from '../DeviceCheckDialog';

const useAuthorization = (firebase, condition) => {
  const [state] = useState({ firebaseStarted: false });
  const { search } = useLocation();

  useEffect(() => {
    if (firebase && !state.firebaseStarted) {
      const listener = firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            navigate(ROUTES.SIGN_IN);
          } else if (!skipDeviceCheck(search) && authUser && !authUser.deviceCheck && !authUser.ignoreDeviceCheck) {
            navigate(ROUTES.APP_BROWSER_CHECK);
          }
        },
        () => navigate(ROUTES.SIGN_IN),
      );
      return listener;
    }
    return null; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, firebase, state.firebaseStarted, skipDeviceCheck, search]);
}

const withAuthorization = (condition, skipDeviceCheck) => Component => {
  const WithAuthorization = props => {
    useAuthorization(props.firebase, condition, skipDeviceCheck);
    return (
      <AuthUserContext.Consumer>
        {authUser =>
          condition(authUser) ? <>
            <Component {...props} />
          </> : null
        }
      </AuthUserContext.Consumer>
    );
  }
  return withFirebase(WithAuthorization);
}

export default withAuthorization;
