import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const translations = {
  saveSuccess: 'Your Answers have been saved',
  messageSent: 'Your message has been sent',
  error: 'An error ocurred',
  tutorialsCreated: 'Course Scheduled Correctly',
  success: 'Success!',
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const ALERT_SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export const SNACKBAR_CLOSED = {
  isOpen: false,
};

export const SNACKBAR_SAVE_SUCCESS = {
  text: translations.saveSuccess,
  severity: ALERT_SEVERITY.SUCCESS,
  isOpen: true,
};

export let SNACKBAR_TUTORIALS_CREATED_SUCCESS = {
  text: translations.tutorialsCreated,
  severity: ALERT_SEVERITY.SUCCESS,
  isOpen: true,
}

export const SNACKBAR_MESSAGE_SENT = {
  text: translations.messageSent,
  severity: ALERT_SEVERITY.SUCCESS,
  isOpen: true,
};
export const SNACKBAR_SUCCESS = (text = translations.success) => ({
  text: text,
  severity: ALERT_SEVERITY.SUCCESS,
  isOpen: true,
});

export const SNACKBAR_ERROR = (text = translations.error) => ({
  text: text,
  severity: ALERT_SEVERITY.ERROR,
  isOpen: true,
  duration: 10000,
});

export default function CustomizedSnackbar({ data, handleClose, ..._ }) {
  const classes = useStyles();
  const { severity, text, isOpen, duration } = data;
  return (
    <div className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={duration || 2000}
                onClose={handleClose} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}>
        <Alert onClose={handleClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}
export const handleSnackbarClose = (setSnackbar) => (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setSnackbar(snackbar => ({ ...snackbar, isOpen: false }));
};