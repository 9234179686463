import React, { useLayoutEffect, useState } from 'react';
import { Badge, Box, Typography } from '@material-ui/core';
import Button from '../../../components/Button';
import * as ROUTES from '../../../constants/routes';
import { fetchCollectionFromFirestore } from '../../lib/firebaseHelper';
import { currentWebinarsTutorIsNotOnline } from './webinar-info/helper';
import { serverTime } from '../../helpers/dateChecker';

const translations = {
  sections: {
    admin: 'Admin Dashboard',
    sellCourse: 'ClassGroup Admin',
    newClassGroup: 'New ClassGroup',
    recordings: 'Webinar recordings',
    adminUser: 'Users Admin',
    webinarDashboard: {
      primary: 'Webinar info',
      error: 'Tutor offline',
    },
    tutorialScheduling: 'Tutorial Scheduling',
    messages: 'Message View',
  },
};

export function AdminNavBar(props) {
  const [state, setState] = useState({
    loading: true,
    data: [],
    firebaseStarted: false,
    initalized: false,
  });

  useLayoutEffect(() => {
    let unsubscribe;
    const getPastDayWebinarInfo = async () => {
      const today = serverTime();
      const yesterday = today.getTime() - (24 * 60 * 60 * 1000);
      const courseRef = props.firebase.db
        .collection('recordings')
        .where('timestamp', '>', yesterday);
      unsubscribe = fetchCollectionFromFirestore(courseRef, setState);
    };
    if (props.firebase && !state.firebaseStarted) {
      getPastDayWebinarInfo();
    }
    return unsubscribe;
  }, [props.firebase, state.firebaseStarted]);

  const currentWebinarsWhereTutorIsNotOnline =
    (state.data && currentWebinarsTutorIsNotOnline(state.data)) || [];

  return (
    <Box p={2}>
      <Button color="secondary" to={ROUTES.APP_ADMIN}>
        <Typography variant="h6">{translations.sections.admin}</Typography>
      </Button>
      <Button color="secondary" to={ROUTES.APP_ADMIN_SELL}>
        <Typography variant="h6">{translations.sections.sellCourse}</Typography>
      </Button>
      <Button color="secondary" to={ROUTES.APP_ADMIN_CLASS_GROUP_CREATION}>
        <Typography variant="h6">{translations.sections.newClassGroup}</Typography>
      </Button>
      <Button color="secondary" to={ROUTES.APP_ADMIN_RECORDINGS}>
        <Typography variant="h6">{translations.sections.recordings}</Typography>
      </Button>
      <Button color="secondary" to={ROUTES.APP_ADMIN_USERS}>
        <Typography variant="h6">{translations.sections.adminUser}</Typography>
      </Button>
      <Button color="secondary" to={ROUTES.APP_ADMIN_WEBINAR_INFO}>
        {!!currentWebinarsWhereTutorIsNotOnline.length ? (
          <Badge
            badgeContent=" "
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Typography variant="h6">
              {translations.sections.webinarDashboard.error}
            </Typography>
          </Badge>
        ) : (
          <Typography variant="h6">
            {translations.sections.webinarDashboard.primary}
          </Typography>
        )}
      </Button>
      <Button color="secondary" to={ROUTES.APP_ADMIN_TUTORIALS}>
        <Typography variant="h6">
          {translations.sections.tutorialScheduling}
        </Typography>
      </Button>
      <Button color="secondary" to={ROUTES.APP_ADMIN_MESSAGE_VIEW}>
        <Typography variant="h6">{translations.sections.messages}</Typography>
      </Button>
    </Box>
  );
}
