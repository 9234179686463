import { CONTENT_TYPES } from '../../constants/contentTypes';
import { callCloudFunction } from '../../pages/admin/sell/CallCloudFunction';

const callRestFunction = async (url, body) => {
  const data = body;
  const request = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  };
  const response = await fetch(url, request);
  return response.json();
};

const config = {
  apiKey: process.env.GATSBY_API_KEY,
  authDomain: process.env.GATSBY_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_DATABASE_URL,
  projectId: process.env.GATSBY_PROJECT_ID,
  storageBucket: process.env.GATSBY_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
  measurementId: process.env.GATSBY_MEASUREMENT_ID,
  appId: process.env.GATSBY_APP_ID,
};

class Firebase {
  constructor(app) {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    this.serverTime = () => app.firestore.Timestamp.now().toDate();
    
    /* Firebase APIs */

    this.auth = app.auth();
    this.functions = app.functions();
    /* db */
    const settings = {
      host: (process.env.GATSBY_CHINA_MODE === 'on') ?
        process.env.GATSBY_CHINA_DB_HOST || 'db1.melioeducation.com'
        : 'firestore.googleapis.com',
    };
    app.firestore().settings(settings);
    this.db = app.firestore();

    if (process.env.GATSBY_CHINA_MODE === 'on') {
      const fnHost = process.env.GATSBY_CHINA_FUNCTIONS_HOST || `fn1.melioeducation.com`;
      this.functions._url = name => {
        return `https://${fnHost}/${name}`;
      };
    }
    if (process.env.GATSBY_LOCAL === 'true') {
      this.functions.useFunctionsEmulator("http://localhost:5001")
    }

    this.storage = app.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = async (email, password, roles, fullName, region = 'occident') => {
    const { data } = await callCloudFunction(this, {
      email, password, fullName,
      roles,
      region,
    }, 'authentication-callCreateUserWithEmailAndPassword');
    if (data.error) {
      console.log('data.error', data.error); //TODO
      throw data.error;
    }
    return data;
  };

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithEmailLink = (email, link) =>
    this.auth.signInWithEmailLink(email, link);

  doSignInWithToken = async (email, password) => {
    const result = await callRestFunction('https://fn1.melioeducation.com/authentication-createCustomTokenWithPassword', {
      email,
      password,
    });
    await firebase.auth.signInWithCustomToken(result.customToken);
  };

  doSignOut = () => this.auth.signOut();

  doPasswordResetRequest = async email => await callCloudFunction(firebase, {email}, 'authentication-callResetPasswordRequest');

  doPasswordResetSet = async (email, code, password) => await callCloudFunction(firebase, {email, code, password}, 'authentication-callResetPasswordSet');

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
    });

  doSendSignInLinkToEmail = (email) =>
    this.auth.sendSignInLinkToEmail(email, {
      url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT + '/app/account',
      handleCodeInApp: true,
    });

  doPasswordUpdate = async (password) => await callCloudFunction(firebase, { password }, 'authentication-callUpdatePassword');

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  isAdmin = user => {
    return user.roles['ADMIN'] === 'ADMIN'
  };

  isTutor = user => user.roles['TUTOR'] === 'TUTOR';

  isStudent = user => !(this.isTutor(user) || this.isAdmin(user));

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  // *** Message API ***

  channels = () => this.db.collection(`chat`);
  channel = channelId => this.channels().doc(channelId);
  userChannels = authUser => this.channels().where('userIds', 'array-contains', authUser.uid);

  unReadMessagesInChannel = (authUser, channel) => this.db.collection(`chat/${channel.chatRoomId}/messages`)
    .where('unReadBy', 'array-contains', authUser.uid);

  messages = (channelId) => this.channel(channelId).collection('messages/');

  message = (channelId, messageId) => this.messages(channelId).doc(messageId);

  // *** Courses API ***


  course = uid => this.db.doc(`courses/${uid}`);

  courses = () => this.db.collection('courses/');

  userCourseRef = (userId, classGroupId, courseId) => `users/${userId}/classGroups/${classGroupId}/courses/${courseId}`;

  userActivitiesRef = (userId, classGroupId, courseId) => `${this.userCourseRef(userId, classGroupId, courseId)}/activity`;
  userActivityRef = (userId, classGroupId, courseId, activityId) => `${this.userActivitiesRef(userId, classGroupId, courseId)}/${activityId}`;
  userTopicsRef = (userId, classGroupId, courseId) => `${this.userCourseRef(userId, classGroupId, courseId)}/topic`;
  userTopicRef = (userId, classGroupId, courseId, topicId) => `${this.userTopicsRef(userId, classGroupId, courseId)}/${topicId}`;

  userclassGroups = userId => this.user(userId).collection('classGroups');
  userclassGroup = (userId, classGroupId) => this.userclassGroups(userId).doc(classGroupId);
  userCourse = (userId, classGroupId, courseId) => this.userclassGroup(userId, classGroupId).collection('courses').doc(courseId);
  userActivities = (userId, courseId, classGroupId) => this.userCourse(userId, courseId, classGroupId).collection('activity');
  userActivity = (userId, courseId, classGroupId, activityId) => this.userActivities(userId, courseId, classGroupId).doc(activityId);
  userTopics = (userId, courseId, classGroupId) => this.userCourse(userId, courseId, classGroupId).collection('topic');
  userTopic = (userId, courseId, classGroupId, topicId) => this.userTopics(userId, courseId, classGroupId).doc(topicId);
  userCourseContent = (userId, courseId, classGroupId, courseContentType, courseContentId) => this.userCourse(userId, courseId, classGroupId).collection(courseContentType).doc(courseContentId);
  userNotifications = (userId) => this.user(userId).collection('notifications');
  userNotificationsUnread = (userId) => this.user(userId).collection('notifications').where('status', '==', 'unread');
  userNotification = (userId, channelId, messageId) => this.userNotifications(userId).doc(channelId + '_' + messageId);
  published = () => this.db.collection(`published`);

  drafts = () => this.db.collection(`drafts`);
  courseActivities = (courseId) => this.published().doc(courseId).collection('activity')

  classGroups = () => this.db.collection('classGroups');
  classGroup = (id) => this.classGroups().doc(id);

  coursesWithinModule = () => this.db.collection(`live/courses/course`).orderBy("order");
  modulesWithinProgramme = () => this.db.collection(`live/modules/module`).orderBy("order");
  programmes = () => this.db.collection(`live/programmes/programme`);

  classGroupCourse = (classGroupId, courseId) => this.classGroup(classGroupId).collection('courses').doc(courseId);
  classGroupTutorials = (classGroupId) => this.classGroup(classGroupId).collection('tutorials');
  classGroupTutorial = (classGroupId, tutorialId) => this.classGroup(classGroupId).collection('tutorials').doc(tutorialId);

  progress = () => this.db.collection('progress');
  progressclassGroup = (userId, classGroupId, courseId) => this.progress()
    .where('classGroupId', '==', classGroupId)
    .where('courseId', '==', courseId)
    .where('userId', '==', userId);
  progressclassGroupIncomplete = (userId, classGroupId, courseId) =>
    this.progressclassGroup(userId, classGroupId, courseId).where('completed', '==', false);

  storageBasicUploadRefString = (courseId, userId, classGroupId, courseContentId) => `courses/${courseId}/users/${userId}/classGroups/${classGroupId}/${CONTENT_TYPES.COURSE_CONTENT_UPLOAD_ASSIGNMENT}/${courseContentId}`;
}

let firebase;

function getFirebase(app, auth, database, functions, storage) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database, functions, storage);
  }

  return firebase;
}

export default getFirebase;
