import { DateTime } from 'luxon';
import getFirebase from '../components/Firebase';

export const formatFullDateTime = (date) => {
  const lDate = DateTime.fromJSDate(date);
  const formatted = lDate.toFormat(`ccc LLL d HH':'mm '${lDate.offsetNameShort}' '(GMT'ZZZ')'`);
  return formatted;
}

export const serverTime = (useClientDateFallback = true) => {
  try {
    return getFirebase().serverTime();
  } catch (error) {
    return !!useClientDateFallback ? new Date() : undefined;
  }
};

export const serverTimeInSeconds = (useClientDateFallback = true) => {
  try {
    return Math.round((new Date(getFirebase().serverTime()).getTime()) / 1000);
  } catch (error) {
    return !!useClientDateFallback ? Math.round((new Date()).getTime()) : undefined;
  }
  
}

export const isPastDeadline = deadline => deadline && deadline.toDate() < serverTime();

export const translations = {
  studentList: 'Students in this tutorial:',
  expired: (deadline) => `This content is not accessible. ${tryGetLocaleDateString(deadline, 'It expired on')}`,

};

/*
  use this to enable all activities without availablility date
  export const isActivityAvailable = (activity) => activity.availableFrom ? true : true;
*/

const DAY_IN_SECONDS = 24 * 60 * 60

const lessThanADay = (date) => {
  // console.log('DAY_IN_SECONDS',DAY_IN_SECONDS)
  // console.log('date',date)
  // console.log('date.seconds)', date.seconds)
  // console.log('serverTime().getDate()', serverTimeInSeconds())
  // console.log('delta', serverTimeInSeconds() - date.seconds)
  // console.log('delta',((( serverTimeInSeconds() - date.seconds) / 60) / 60)/ 24)
  // console.log('toSeconds(serverTime().getDate()) - DAY_IN_SECONDS',  toSeconds(date) - serverTimeInSeconds() - DAY_IN_SECONDS)
  return !!date && (date.seconds > serverTimeInSeconds() - DAY_IN_SECONDS && (date.seconds) < serverTimeInSeconds())
}
const isToday = (date) => lessThanADay(date)
const wasYesterday = (date) => date && (date.seconds > serverTimeInSeconds() - (DAY_IN_SECONDS * 2) && (date.seconds) < serverTimeInSeconds())

export const isActivityAvailable = (activity) => activity.availableFrom ? activity.availableFrom.toDate() < serverTime() : true;

// const dateDayIsToday = date => !!date && date.toDate().getDate() === serverTime().getDate();

// const dateDayIsYesterday = date => !!date && date.toDate().getDate() === serverTime().getDate() - 1;

export function isExpiringToday(activity) {
  console.log('activity.deadline', activity.title, isToday(activity.deadline))
  console.log('activity.deadlineLenient', activity.title, isToday(activity.deadlineLenient))
  return isToday(activity.deadline) || isToday(activity.deadlineLenient);
}

export const expiredYesterday = activity => {
  return wasYesterday(activity.deadlineLenient)
}

export function tryGetLocaleDateString(date, text) {
  try {
      const localDate = `${date.toDate().toLocaleDateString()}`;
    if (!!text)
      return `${text} ${localDate}`;
    return localDate;
  } catch (e) {
    return '';
  }
}

export function tryGetLocaleDateTimeString(date, text) {
  try {
    const formatted = formatFullDateTime(date.toDate());
    if (!!text)
      return `${text} ${formatted}`;
    return formatted;
  } catch (e) {
    return '';
  }
}

export const isInLeniencyPeriod = (deadline, deadlineLenient) => isPastDeadline(deadline) && !isPastDeadline(deadlineLenient);

export const dateFromISOString = (timestamp) => new Date(timestamp);
export const dateFromFirebaseTimestamp = (timestamp) => timestamp.toDate();
