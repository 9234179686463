import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ROUTES from '../../constants/routes';
import GatsbyLinkedButton from '../../components/Button';
import useBrowserCheck from '../../hooks/useBrowserCheck';
import { userMustCheckDevice } from './SystemChecks/deviceDataCheck';
import AuthUserContext from './Session/context';
import queryString from 'query-string';
import {
  DEVICE_CHECK_TEXT_CONTENTFUL_ENTRY,
  getTextFromContentfulEntry,
} from '../lib/ContentfulHelper';

const SKIP_SUPER_USER = 'nodevch';

export const skipDeviceCheck = (search) => {
  const { superUser } = queryString.parse(search) ?? false;
  return (!!superUser && superUser === SKIP_SUPER_USER);
};

export default function DeviceCheckAlertDialog({ router, firebase }) {
  const [open, setOpen] = React.useState(false);
  const { detectedBrowser, platform } = useBrowserCheck();
  const [contentfulText, setContentfulText] = useState();


  const authUser = useContext(AuthUserContext);
  useEffect(() => {
    const getEntry = async () => {
      const { result, output } = await getTextFromContentfulEntry(firebase, DEVICE_CHECK_TEXT_CONTENTFUL_ENTRY);
      setContentfulText(output);
      return result;
    };

    if (!!authUser && !authUser.ignoreDeviceCheck && !!authUser.deviceCheck && !skipDeviceCheck(router.location.search))
      userMustCheckDevice(authUser, navigator, detectedBrowser, platform).then(result => {
        getEntry().then(() => setOpen(result));
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, detectedBrowser]);

  return (
    <div>
      {!!contentfulText &&
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {contentfulText.dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentfulText.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GatsbyLinkedButton onClick={() => setOpen(false)}
                              to={ROUTES.APP_BROWSER_CHECK}
                              color="primary">
            {contentfulText.dialogButtonText}
          </GatsbyLinkedButton>
        </DialogActions>
      </Dialog>
      }
    </div>
  );
}
