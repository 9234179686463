const isBrowser = (typeof window !== `undefined`);

const getContentfulEntryById = async (firebase, entryId) => {
  if (isBrowser) {
    const data = {
      'entryId': entryId,
    };
    
    // eslint-disable-next-line no-undef
    if (process.env.GATSBY_LOCAL === "true") {
      firebase.functions.useFunctionsEmulator("http://localhost:5001")
    }

    const callable = firebase.functions.httpsCallable('backend-GetContentfulEntryById');
    const result = await callable(data);
    return result;
  }
};

export default getContentfulEntryById;
