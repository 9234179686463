import React from 'react';

import IconButton from "@material-ui/core/IconButton";
import GatsbyLink from "../gatsby-link";

const GatsbyLinkedIcon = props => {
  const { to, href } = props;
  const component = to || href ? GatsbyLink : `button`;

  return <IconButton component={component} {...props} />;
}

export default GatsbyLinkedIcon;