import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  ButtonGroup,
  IconButton,
  Menu,
  Slide,
  Toolbar,
} from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { AuthUserContext } from '../Session';
import menuIcon from '../../../assets/icons/menu-icon.svg';
import MELIO_LOGO from '../../../assets/melio-logo.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import * as ROUTES from '../../../constants/routes';
import Button from '../../../components/Button';
import Box from '@material-ui/core/Box';
import { Notifications } from './Notifications';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px'
  },
  button: {
    display: 'block'
  },
  appbar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: '75px',
    width: 'auto',
  },
}));

const AuthenticatedTopBar = props => (
  <AuthUserContext.Consumer>
    {authUser =>
      <TopBar authUser={authUser} {...props} />
    }
  </AuthUserContext.Consumer>
);

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const MenuDisplay = (props) => {
  const classes = useStyles();
  const { authUser, firebase } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!authUser && <></>}
      {authUser && 
        <ClickAwayListener onClickAway={handleClose}>
        <React.Fragment>
        <IconButton 
          edge="start" 
          className={classes.menuButton} 
          color="secondary" 
          aria-label="menu" 
          aria-controls="simple-menu" 
          aria-haspopup="true" 
          onClick={handleClick}>
          <img src={menuIcon} alt="menu"/>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <ButtonGroup
            className={classes.root}
            variant="text"
            size="large"
            aria-label="large full width text button group">
            {authUser && (
              <Button classes={{ root: classes.button }} to={ROUTES.APP_COURSES}>Courses</Button>
              )}
            {authUser && (
              <Button classes={{ root: classes.button }} to={ROUTES.APP_MESSAGES}>Messages</Button>
            )}
            {authUser && (
              <Button classes={{ root: classes.button }} to={ROUTES.APP_ACCOUNT}>Account</Button>
              )}
            {(authUser && firebase && firebase.isAdmin(authUser)) && (
              <Button classes={{ root: classes.button }} to={ROUTES.APP_ADMIN}>Admin</Button>
              )}
            {authUser && (
              <Button classes={{ root: classes.button }} to={ROUTES.APP_CONTACT}>Contact Us</Button>
              )}
            {authUser && firebase && (firebase.isAdmin(authUser) || firebase.isTutor(authUser)) && (
              <Button classes={{ root: classes.button }}
                      to={ROUTES.APP_TUTOR_FEEDBACK}>Tutor</Button>
              )}
          </ButtonGroup>
        </Menu>
        </React.Fragment>
        </ClickAwayListener>
    }
    </>
  )
}

const TopBar = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HideOnScroll {...props}>
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <img className={classes.logo} alt="" src={MELIO_LOGO}/>
            <Box display={"flex"} alignItems="center">
              <Notifications {...props}/>
              <MenuDisplay {...props} />
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}

export default AuthenticatedTopBar
