const noop = () => {
};
export async function fetchFromFirestoreOnce(reference) {
  let doc = await reference.get();
  try {
    if (!doc.exists) {
      console.log('No such document!');
    } else {
      return doc.data();
    }
  } catch (err) {
    console.log('Error getting document', err);
  }
}

export function fetchCollectionFromFirestoreOnce(reference) {
  return reference.get().then(snapshot => {
    let data = [];
    if (snapshot.size) {
      snapshot.forEach(doc => data.push({
        ...doc.data(),
        uid: doc.id,
      }));
    }
    return data;
  });
}

export function fetchFromFirestorePlainBasic(reference, setStateFunction, initialState) {
  if (setStateFunction) {
    return reference
    .onSnapshot((snapshot) => {
      if (snapshot.exists) {
        setStateFunction({ ...snapshot.data() });
      } else {
        setStateFunction(initialState);
      }
    }, (error) => {
      console.log('error: ' + error);
      setStateFunction(false);
    });
  }
}

export function fetchCollectionFromFirestoreBasic(reference, setStateFunction, transformation = (d) => d, onEnd = () => {}) {
  return reference.onSnapshot(snapshot => {
    let data = [];
    if (snapshot.size) {
      snapshot.forEach(doc => data.push({
        ...doc.data(),
        uid: doc.id,
      }));
    }
    let response = transformation ? transformation(data) : data;
    setStateFunction([...response]);
    onEnd()
  });
}

export function fetchCollectionFromFirestoreAwaitBasic(reference, setStateFunction, transformation) {
  return reference.onSnapshot(snapshot => {
    let data = [];
    if (snapshot.size) {
      snapshot.forEach(doc => data.push({
        ...doc.data(),
        uid: doc.id,
      }));
    }
    transformation(data).then((response) => {
      return setStateFunction(response);
    })
    ;
  });
}

export function fetchFromFirestore(reference, setStateFunction, onStart = noop, onComplete = noop) {
  onStart();
  return reference
    .onSnapshot((snapshot) => {
      if (snapshot.exists) {
        let data = snapshot.data();
        setStateFunction(state => ({
          ...state,
          data: data,
          loading: false,
          firebaseStarted: true,
        }));
        onComplete(true);
      }
    }, (error) => {
      console.log('error: ' + error);
      setStateFunction(state => ({ ...state, loading: false }));
    });
}

export function fetchFromFirestoreWithCallback(reference, setStateFunction, callback) {
  return reference
    .onSnapshot(async (snapshot) => {
      if (snapshot.exists) {
        let response = snapshot.data();
        if (!!callback)
          response = await callback(response);
        setStateFunction(state => ({
          ...state,
          data: response,
          loading: false,
          firebaseStarted: true,
        }));
      }
    }, (error) => {
      console.log('error: ' + error);
      setStateFunction(state => ({ ...state, loading: false }));
    });
}

export function fetchCollectionFromFirestore(reference, setStateFunction, onStart = noop, onComplete = noop) {
  onStart();
  return reference.onSnapshot(snapshot => {
    let data = [];
    if (snapshot.size) {
      snapshot.forEach(doc => data.push({
        ...doc.data(),
        uid: doc.id,
      }));
    }
    setStateFunction(state => ({
      ...state,
      data: data,
      loading: false,
    }));
    onComplete();
  });
}

export function fetchCollectionFromFirestoreWithTransformation(reference, setStateFunction, transformation, onStart = noop, onComplete = noop) {
  onStart();
  return reference
    .onSnapshot((snapshot) => {
      let data = [];
      if (snapshot.size) {
        snapshot.forEach(doc => data.push({
          ...doc.data(),
          uid: doc.id,
        }));
      }
      let response = transformation(data);
      setStateFunction(state => ({
        ...state,
        data: { ...response },
        loading: false,
      }));
      onComplete();
    }, (error) => {
      console.log('error: ' + error);
      setStateFunction(state => ({ ...state, loading: false }));
    });
}


export function fetchCollectionFromFirestoreWithArrayTransformation(reference, setStateFunction, transformation, onStart = noop, onComplete = noop) {
  onStart();
  return reference
    .onSnapshot((snapshot) => {
      let data = [];
      if (snapshot.size) {
        snapshot.forEach(doc => data.push({
          ...doc.data(),
          uid: doc.id,
        }));
      }
      let response = transformation(data);
      setStateFunction(state => ({
        ...state,
        data: [ ...response ],
        loading: false,
      }));
      onComplete();
    }, (error) => {
      console.log('error: ' + error);
      setStateFunction(state => ({ ...state, loading: false }));
    });
}

export const fetchFilesFromStorage = async (reference, setStateFunction, onStart = noop, onComplete = noop) => {
  onStart();
  const results = await reference.listAll();
  setStateFunction(state => ({
    ...state,
    data: results,
  }));
  onComplete();
};

export const fetchQuizDataFromCloudFunction = async (firebase, data) => {
  try {
    // eslint-disable-next-line no-undef
    if (process.env.GATSBY_LOCAL === "true") {
      firebase.functions.useFunctionsEmulator("http://localhost:5001")
    }

    const callable = firebase.functions.httpsCallable('backend-GetQuizAnswers');

    return await callable(data);
  } catch (error) {
    console.log('error', error);
  }
};
